import styled from "styled-components";
import Header from "../../components/AgentRegistration/Header";
import Services from "../../components/AgentRegistration/Services";
import ApplyHow from "../../components/AgentRegistration/ApplyHow";
import SmallBuissness from "../../components/AgentRegistration/SmallBuissness";

const AgentRegistration = () => {
  const Container = styled.div`
    padding: 0 20px;

    @media (min-width: 768px) {
      padding: 0 60px;
    }

    @media (min-width: 1024px) {
      padding: 0 100px;
    }

    @media (min-width: 1280px) {
      padding: 0 134px;
    }
  `;

  const ServicesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media (min-width: 1024px) {
      gap: 100px;
      > img {
        width: 300px;
      }
    }

    @media (min-width: 1780px) {
      gap: 262px;
      > img {
        width: auto;
      }
    }

    > img {
      position: relative;
      bottom: 0;
      max-width: 100%;
      margin-top: 20px;
      object-fit: cover;

      @media (min-width: 1024px) {
        bottom: 180px;
        margin-top: 0;
      }

      @media (max-width: 1280px) {
        display: none;
      }
    }
  `;

  return (
    <>
      <Header />
      <Container>
        <ServicesContainer>
          <Services />
        </ServicesContainer>
      </Container>
      <ApplyHow />
      <SmallBuissness />
    </>
  );
};

export default AgentRegistration;
