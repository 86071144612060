import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0 32px 60px;
  text-align: center;
  overflow: hidden;
`;

const Medium = css`
  text-align: right;
  padding: 100px 52px;
`;

const Large = css`
  padding: 103px 50px 107px;
`;

const XLarge = css`
  padding: 103px 111px 90px;
`;

const XXLarge = css`
  padding: 103px 141px 90px;
`;

export const SHeroContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
