import styled from "styled-components";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import worker from "../../assets/images/assign-employee.png";
import click2 from "../../assets/images/click2.svg";
import right from "../../assets/images/right.svg";

const SmallBuissness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const requirements = [
    "هوية وطنية سارية أو رخصة عمل حر",
    "صورة من الهوية الوطنية “ يمكن تغطية الوجه للإناث ”",
    "التفعيل فوري للسعوديين",
    "للأجانب يستلزم التسجيل كشركة",
    "سجل تجاري للشركات أو المشاريع الصغيرة",
    "رقم الآيبان ويجب أن يكون مطابق لإسم مقدم الخدمة",
  ];

  const Wrapper = styled.div`
    padding: 0 20px;

    @media (min-width: 768px) {
      padding: 0 60px;
    }
    @media (min-width: 1024px) {
      padding: 0 100px;
    }
    @media (min-width: 1280px) {
      padding: 0 134px;
    }
  `;
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 15px;

    @media (min-width: 768px) {
      gap: 32px;
    }

    @media (min-width: 1469px) {
      flex-direction: row;
      align-items: flex-start;
      text-align: right;
      gap: 48px;
    }
  `;

  const Content = styled.div`
    max-width: 600px;
  `;

  const Title = styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: #0a1730;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    @media (min-width: 1024px) {
      font-size: 32px;
      margin-bottom: 0;
    }
  `;

  const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 52px;
    border-radius: 7px;
    background: #e5f97f;
    font-size: 15px;
    font-weight: 700;
    color: #202020;
    transition: all 0.3s ease;
    cursor: pointer;
    margin: 0 0 50px;

    &:hover {
      background: #ecfaa4;
      transform: translateY(-2px);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 768px) {
      margin: 0 auto 40px;
    }

    @media (min-width: 1024px) {
      margin: 20px auto 100px;
    }
    @media (min-width: 1469px) {
      margin: 40px auto 100px;
    }
  `;

  const Service = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 20px 0;

    @media (min-width: 768px) {
      gap: 20px;
      margin: 24px 0;
    }

    @media (min-width: 1024px) {
      gap: 30px;
      margin: 28px 0;
    }
  `;
  const RightIcon = styled.img`
    width: 20px;
    height: 20px;

    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
  `;

  const ServiceTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
    color: #0a1730;

    @media (min-width: 768px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
    }
  `;

  const WorkerImage = styled.img`
    width: 100%;
    max-height: 700px;
    object-fit: cover;

    @media (min-width: 1466px) {
      width: auto;
      height: 500px;
      max-width: none;
    }
  `;

  return (
    <Wrapper>
      <Container>
        <WorkerImage src={worker} alt="Worker" data-aos="fade-left" />
        <Content>
          <div data-aos="fade-up">
            <Title>شروط التسجيل كمقدم خدمة :</Title>
          </div>
          {requirements.map((requirement, index) => (
            <Service key={index} data-aos="fade-left" data-aos-delay="100">
              <RightIcon src={right} alt="" />
              <ServiceTitle>{requirement}</ServiceTitle>
            </Service>
          ))}
        </Content>
      </Container>
      <Button data-aos="fade-up" href="/registration/form">
        <img src={click2} alt="Icon" />
        سجل الأن
      </Button>
    </Wrapper>
  );
};

export default SmallBuissness;
