import { useEffect, useState } from "react";
import "../privacy-policy/privacy.css";

const About = () => {
  const categories = [
    {
      id: 1,
      title: "بيزي كمنصة تقديم خدمات",
      content: `
     <div>
  <p>
    يتيح بيزي للأفراد والشركات الوصول إلى شبكة واسعة من المهنيين المستقلين لتنفيذ مهام قصيرة الأجل أو مشاريع محددة.
  </p>

  <h2>لماذا أستخدم بيزي؟</h2>
  <ol>
    <li>خدمات متنوعة: يقدم مجموعة واسعة من الخدمات تناسب مختلف الاحتياجات، سواء للأفراد أو الشركات، مما يعكس مرونة الاقتصاد القائم على المهام.</li>
    <li>التحكم في الميزانية: يسمح للمستخدمين بتحديد ميزانية الخدمة وفق إمكانياتهم، مما يمنحهم مرونة مالية.</li>
    <li>تقييمات موثوقة: يعرض تقييمات حقيقية من العملاء السابقين، مما يساعد في اتخاذ قرارات مستنيرة.</li>
    <li>تنوع في العروض: يوفر خيارات متعددة تلبي تفضيلات واحتياجات المستخدمين المختلفة، مما يعزز التجربة الشاملة.</li>
    <li>لأصحاب المشاريع الصغيرة: يتيح لهم الوصول إلى مستقلين في مختلف المجالات دون الحاجة إلى التوظيف الدائم.</li>
  </ol>

  <h2>كيف تقوم بالطلب من بيزي؟</h2>
  <ol>
    <li>التسجيل: إنشاء حساب في التطبيق وتسجيل البيانات الأساسية.</li>
    <li>اختيار الخدمة: تصفح الخدمات المتاحة واختيار الخدمة المناسبة للطلب.</li>
    <li>تحديد التفاصيل: تحديد تفاصيل المهمة، مثل الوقت والموقع والتكلفة المتوقعة.</li>
    <li>إرسال الطلب: تقديم الطلب ليصل إلى مجموعة من المهنيين المستقلين المناسبين.</li>
    <li>استقبال العروض: استعراض العروض المرسلة واختيار الأنسب.</li>
    <li>التواصل والتأكيد: التواصل مع المهني المختار داخل التطبيق لأي تفاصيل إضافية.</li>
    <li>إتمام الدفع: إجراء الدفع بأمان عبر التطبيق بعد الانتهاء من العمل.</li>
  </ol>

  <h2>كيف يخدم بيزي أصحاب المشاريع الصغيرة من خلال طلب الخدمات ؟</h2>
  <p>
    تطبيق بيزي يمثل فرصة فريدة لأصحاب المشاريع الصغيرة للاستمرار في السوق من خلال التوظيف المؤقت سواء بالساعة أو بمهام محددة. بينما يحمل التوظيف الدائم مخاطر كتكاليف الرواتب المستمرة والالتزامات القانونية، يتيح بيزي لأصحاب المشاريع الصغيرة مثل المطاعم والمقاهي وغيرها الوصول إلى مهنيين مستقلين حسب الحاجة، خاصة في أوقات الذروة أو المواسم. هذا يعزز الكفاءة ويقلل من التكاليف، مما يمنح أصحاب المشاريع مرونة أكبر في إدارة الموارد وتحقيق الإنتاجية المطلوبة دون الحاجة إلى التوظيف الدائم.
  </p>
</div>

    `,
    },
    {
      id: 2,
      title: "بيزي كمنصة عمل مؤقت",
      content: `
    <div>
  <p>
    يتيح بيزي للأفراد وأصحاب المشاريع الصغيرة فرص لزيادة دخلهم من خلال توفير فرص عمل متنوعة وبسيطة.
  </p>

  <h2>ماذا يقدم بيزي لمقدمي الخدمات؟</h2>
  <ul>
    <li>دخل إضافي للأفراد: مما يساعد على تنويع خبراتهم وزيادة دخلهم.</li>
    <li>فرص عمل متنوعة: يتيح الوصول إلى مجموعة كبيرة من المشاريع الصغيرة والمهام المتنوعة البسيطة، مما يعكس روح الاقتصاد القائم على المهام.</li>
    <li>المرونة في العمل: يسمح باختيار المشاريع وفقاً للجدول الزمني والتفضيلات الشخصية، مما يسهل تحقيق التوازن بين الحياة المهنية والشخصية.</li>
    <li>معاملات مالية آمنة: يوفر آليات دفع موثوقة وعقود إلكترونية تحفظ حقوق المستقلين وتضمن استلام الأجور.</li>
    <li>عمولة صفرية: لا يفرض بيزي أي عمولات على الأرباح، مما يسمح لمقدمي الخدمات بالاحتفاظ بجميع العوائد من الأعمال التي ينفذونها.</li>
    <li>تطوير السمعة المهنية: يتيح نظام التقييمات للعملاء تقديم ملاحظاتهم، مما يساعد في بناء سمعة قوية وجذب فرص جديدة.</li>
  </ul>

  <h2>كيف تقوم بالتسجيل كمقدم خدمة؟</h2>
  <ol>
    <li>إنشاء حساب: من خلال الدخول على صفحة التسجيل وتعبئة البيانات.</li>
    <li>تحميل التطبيق: سيظهر رابط تحميل تطبيق مقدم الخدمة في نهاية التسجيل أو من خلال البريد الإلكتروني.</li>
    <li>تحديث الملف الشخصي: أضف معلومات حول المهارات والخبرات.</li>
    <li>تصفح عروض العمل: استعرض المشاريع المتاحة.</li>
    <li>إرسال عروض الأسعار: قدم عرضك وانتظر الموافقة لبدء العمل.</li>
    <li>اسحب أرباحك: قم بسحب أرباحك عند الانتهاء.</li>
  </ol>

  <h2>المشاريع الصغيرة كمقدمي خدمات</h2>
  <p>
    تطبيق بيزي يعد أداة مثالية لأصحاب المشاريع الصغيرة لتوسيع قاعدة عملائهم. كمقدم خدمة، يمكنك الاستفادة من منصة تربطك بعملاء محتملين يبحثون عن متخصصين لتنفيذ مهام معينة. هذا يوفر لك فرصة لعرض مهاراتك، تقديم عروض تنافسية، وزيادة الدخل من خلال استهداف مشاريع متنوعة. كما يسهل عليك بناء سمعة قوية من خلال التقييمات، مما يعزز فرصك في الحصول على المزيد من المشاريع في المستقبل.
  </p>
</div>
    `,
    },
  ];
  let [active, setActive] = useState(categories[0].content);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [active]);
  return (
    <main className="about-page">
      <h1>من نحن</h1>
      <section className="categories">
        {categories?.map(({ id, title, content }) => {
          return (
            <button
              key={id}
              className={`${active === content ? "active-faq" : null} faq-button`}
              onClick={() => setActive(content)}
            >
              {title}
            </button>
          );
        })}
      </section>
      <div className="main-content" dangerouslySetInnerHTML={{ __html: active }}></div>
    </main>
  );
};
//

export default About;
