import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Medium = css``;

const Large = css`
  flex-direction: row;
  gap: 100px;
`;

const XLarge = css`
  gap: 170px;
`;

const XXLarge = css``;

export const SCompDownloadAppWrapper = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
