import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const CSSPrimaryButton = css`
  background: #0a1730;
  color: #e5f97f;
  min-width: 180px;
`;

const CSSSecondaryButton = css`
  border: 2px solid #0a1730;
  color: #101010;
  min-width: 180px;
`;

const CSSTextIconButton = css`
  background: #0a1730;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border: 0;
  color: #e5f97f;

  svg {
    margin-right: 16px;
  }
`;

const Small = css`
  border-radius: 7px;
  display: block;
  cursor: pointer;
  padding: 16px 32px;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  width: 100%;
  max-width: 200px;
  border: 0;
  outline: 0;
  text-align: center;
  text-decoration: none;
  ${({ btnType }) => btnType === "primary" && CSSPrimaryButton};
  ${({ btnType }) => btnType === "secondary" && CSSSecondaryButton};
  ${({ btnType }) => btnType === "with-icon" && CSSTextIconButton};
`;

const Medium = css`
  padding: 18px 40px;
  font-size: 15px;
  max-width: 250px;
`;

const Large = css`
  padding: 20px 44px;
  font-size: 16px;
  min-width: 180px;
  max-width: 300px;
`;

const XLarge = css`
  padding: 24px 48px;
  font-size: 18px;
  min-width: 200px;
  max-width: 350px;
`;

const XXLarge = css``;

export const SButton = styled.a`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
