import styled from "styled-components";
import { Controller, useForm, useWatch } from "react-hook-form";
import active from "../../assets/images/active-radio.svg";
import circle from "../../assets/images/camera.svg";
import notActive from "../../assets/images/not-active-radio.svg";
import { useEffect, useRef, useState } from "react";
import line1 from "../../assets/images/arrow_left_line.svg";
import { format } from "date-fns";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import line2 from "../../assets/images/arrow_left_line_black.svg";
import toast, { Toaster } from "react-hot-toast";

const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 37px;
  padding: 0px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 33px 29px;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 16px;
`;

const Input = styled.input`
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 0 14px;
  width: 100%;
  &:focus {
    outline: none;
    border-color: #6c63ff;
  }

  @media (min-width: 768px) {
    height: 60px;
  }
`;
const Select = styled.select`
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dadada;
  width: 100%;
  display: flex;
  padding: 12.5px 14px;

  &:focus {
    outline: none;
    border-color: #6c63ff;
  }

  @media (min-width: 768px) {
    padding: 18px 14px;
    height: 60px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
  background: #f8f8f8;
  height: 50px;
  padding: 0 15px;
  border-radius: 5px;

  @media (min-width: 768px) {
    height: 60px;
    gap: 43px;
    padding-right: 38.3px;
  }
`;

const SingleColumn = styled.div`
  grid-column: span 1;
  @media (max-width: 1024px) {
    grid-column: span 2;
    display: ${(props) => (props.single ? "none" : "block")};
  }
`;

const DoubleColumn = styled.div`
  grid-column: span 2;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const RadioText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

const CustomRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;

  @media (min-width: 768px) {
    gap: 13px;
    font-size: 14px;
  }
`;
const ProfileCircle = styled.div`
  width: 154px;
  height: 154px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  cursor: pointer;
  > img {
    width: 24px;
    height: 24px;
    opacity: 0.6;
  }
  & .profile-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 1;
    object-fit: cover;
  }
`;
const HiddenInput = styled.input`
  display: none;
`;
const SubLabel = styled.span`
  font-size: 13px;
  font-weight: 300;
  line-height: 15.6px;
  opacity: 0.5;
`;

const Button = styled.button`
  height: 52px;
  border-radius: 7px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #202020;
  color: #e5f97f;
  border: 0;
  margin-bottom: 33px;
  cursor: pointer;
`;

const Header = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a4a4a;
`;

const CalendarWrapper = styled.div`
  position: relative;

  width: 100%;
`;

const CalendarModal = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 10;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  .react-calendar {
    width: 100% !important;
  }
`;

const IbanPhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  overflow: hidden;
  gap: 10px;
  border: 1px solid #dadada;
  border-radius: 5px;
  height: 55px;
  background-color: #f9f9f9;
  padding: 18px 14px;

  @media (min-width: 768px) {
    height: 60px;
  }
`;

const UploadButton = styled.div`
  background: #202020;
  color: #e5f97f;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 100px;
  flex-shrink: 0;

  &:hover {
    background: #333;
  }
`;

const FileName = styled.span`
  font-size: 14px;
  color: #555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  @media (max-width: 458px) {
    align-items: start;
  }
  input {
    opacity: 0;
  }
`;

const StyledCheckbox = styled.label`
  width: 20px;
  height: 20px;
  background-color: #202020;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  @media (max-width: 458px) {
    margin-top: 4px;
  }

  input[type="checkbox"]:checked + & {
    background-color: darkblue;
  }
`;

const CheckIcon = styled.div`
  width: 12px;
  height: 12px;
  background-color: #e5f97f;
  border-radius: 2px;
`;

const LabelText = styled.label`
  font-size: 14px;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
  a {
    color: #2f3800;
    text-decoration: underline;
  }
`;

const MemberForm = ({ handleChangeTab, setAllData, allData, cities }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [ibanPhoto, setIbanPhoto] = useState(null);
  const [buttonAction, setButtonAction] = useState("next");

  const [imageUrl, setImageUrl] = useState(null);
  const [calendars, setCalendars] = useState({
    birthday: null,
    gover_expire_date: null,
  });
  const [showCalendar, setShowCalendar] = useState(null);

  const handleDateChange = (calendarKey, date) => {
    setCalendars((prevState) => ({
      ...prevState,
      [calendarKey]: date,
    }));
    setValue(calendarKey, date, { shouldValidate: true });

    setShowCalendar(null);
  };

  const [selectedGender, setSelectedGender] = useState("");
  const [hasLisence, setHasLisence] = useState("");
  const fileInputRef = useRef(null);
  const type = allData.provider;

  const handleProfileClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    if (image) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
      setProfileImage(image);
    }
  };

  const changeLisence = (type) => {
    setHasLisence(type);
    if (type === "no") {
      setValue("gover_expire_date", null);
      setValue("freelance_doc_number", null);
      setCalendars({
        ...calendars,
        gover_expire_date: null,
      });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    watch,
  } = useForm();

  const handleIbanPhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue("iban_photo", file);
      setIbanPhoto(file?.name);
    }
  };

  useEffect(() => {
    setValue("first", allData.first || "");
    setValue("last", allData.last || "");
    setValue("iban_number", allData.iban_number || "");
    if (type == "person") {
      setValue("gover_id_num", allData.gover_id_num || "");
      setValue("freelance_doc_number", allData.freelance_doc_number || "");
    } else {
      setValue("company_name", allData.company_name || "");
      setValue("company_reg_number", allData.company_reg_number || "");
    }
    setValue("email", allData.email || "");
    setValue(
      "gover_expire_date",
      allData.gover_expire_date ? new Date(allData.gover_expire_date.split("-").reverse().join("-")) : null,
    );
    setValue(
      "birthday",
      allData.birthday ? new Date(allData.birthday.replaceAll("/", "-").split("-").reverse().join("-")) : null,
    );
    setValue("city_id", allData.city_id || "");
    setValue("mobile", allData.mobile || "");
    setValue("password", allData.password || "");
    setValue("confirmPassword", allData.confirmPassword || "");
    setValue("iban_photo", allData?.iban_photo);
    setSelectedGender(allData?.sex);
    setCalendars({
      birthday: allData.birthday
        ? new Date(allData.birthday.replaceAll("/", "-").split("-").reverse().join("-"))
        : null,
      gover_expire_date: allData.gover_expire_date
        ? new Date(allData.gover_expire_date.split("-").reverse().join("-"))
        : null,
    });
    setHasLisence(allData.hasLisence);
  }, [allData, setValue]);

  const onSubmit = (data, action) => {
    const values = {
      ...data,
      image: profileImage || allData.image,
      imageUrl: imageUrl || allData.imageUrl,
      iban_photo: data?.iban_photo || "",
      sex: selectedGender,
      birthday: calendars.birthday ? format(new Date(data.birthday), "dd/MM/yyyy") : null,
      gover_expire_date: calendars.gover_expire_date ? format(new Date(data.gover_expire_date), "dd-MM-yyyy") : null,
      mobile: data.mobile,
      hasLisence,
    };

    setAllData({ ...allData, ...values });
    handleChangeTab(action != "back" && "next");
  };

  const handleBack = () => {
    handleSubmit((data) => {
      onSubmit(
        {
          ...data,
          image: profileImage,
          imageUrl,
          hasLisence,
        },
        "back",
      );
    })();
    handleChangeTab("back");
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <Toaster position="top-center" reverseOrder={false} />

      <SingleColumn>
        <HiddenInput
          type="file"
          id="image"
          name="image"
          {...register("image")}
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <ProfileCircle onClick={handleProfileClick}>
          {imageUrl || allData.imageUrl ? (
            <img className="profile-pic" src={imageUrl || allData.imageUrl} alt="Profile Preview" />
          ) : (
            <img src={circle} alt="Default Profile" />
          )}
        </ProfileCircle>
        {errors.image && <ErrorText>{errors.image.message}</ErrorText>}
      </SingleColumn>
      <SingleColumn></SingleColumn>
      {type === "company" ? (
        <DoubleColumn>
          <Header>معلومات مالك الشركة</Header>
        </DoubleColumn>
      ) : (
        ""
      )}
      <SingleColumn>
        <Label htmlFor="first">الأسم الأول</Label>
        <Input type="text" id="first" {...register("first", { required: "مطلوب" })} />
        {errors.first && <ErrorText>{errors.first.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="last">الأسم الأخير</Label>
        <Input type="text" id="last" {...register("last", { required: "مطلوب" })} />
        {errors.last && <ErrorText>{errors.last.message}</ErrorText>}
      </SingleColumn>

      {type === "person" && (
        <SingleColumn>
          <Label htmlFor="gover_id_num">رقم الهوية الوطنية</Label>
          <Input
            type="number"
            id="gover_id_num"
            {...register("gover_id_num", {
              required: "مطلوب",
              pattern: {
                value: /^1\d{9}$/,
                message: "رقم الهوية يجب أن يبدأ بـ '1' ويليه 9 أرقام",
              },
            })}
          />
          {errors.gover_id_num && <ErrorText>{errors.gover_id_num.message}</ErrorText>}
        </SingleColumn>
      )}

      <SingleColumn>
        <Label htmlFor="birthday">تاريخ الميلاد</Label>
        <Controller
          name="birthday"
          control={control}
          defaultValue={calendars.birthday}
          rules={{
            required: "مطلوب",
            validate: (value) => value < new Date() || "يجب أن يكون التاريخ في الماضي",
          }}
          render={({ field }) => (
            <CalendarWrapper>
              <Input
                type="text"
                {...field}
                value={calendars.birthday ? calendars.birthday.toLocaleDateString() : ""}
                onFocus={() => setShowCalendar(1)}
                readOnly
                placeholder="اختر تاريخ الميلاد"
              />
              {showCalendar == 1 && (
                <CalendarModal>
                  <Calendar
                    onChange={(date) => handleDateChange("birthday", date)}
                    value={calendars.birthday}
                    locale="ar"
                    maxDate={new Date()}
                  />
                </CalendarModal>
              )}
            </CalendarWrapper>
          )}
        />
        {errors.birthday && <ErrorText>{errors.birthday.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label>الجنس</Label>
        <RadioGroup>
          <CustomRadio onClick={() => setSelectedGender("male")}>
            {selectedGender === "male" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
            <RadioText>ذكر</RadioText>
          </CustomRadio>
          <CustomRadio onClick={() => setSelectedGender("female")}>
            {selectedGender === "female" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
            <RadioText>أنثى</RadioText>
          </CustomRadio>
        </RadioGroup>
      </SingleColumn>

      {type === "person" && (
        <SingleColumn>
          <Label>هل تملك رخصة عمل حر ؟</Label>
          <RadioGroup>
            <CustomRadio onClick={() => changeLisence("yes")}>
              {hasLisence === "yes" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
              <RadioText>نعم</RadioText>
            </CustomRadio>
            <CustomRadio onClick={() => changeLisence("no")}>
              {hasLisence === "no" ? <img src={active} alt="" /> : <img src={notActive} alt="" />}
              <RadioText>لا</RadioText>
            </CustomRadio>
          </RadioGroup>
        </SingleColumn>
      )}
      {hasLisence === "yes" && type === "person" ? (
        <>
          <SingleColumn>
            <Label htmlFor="licenseNumber">رقم الرخصة</Label>
            <Input
              type="text"
              id="freelance_doc_number"
              {...register("freelance_doc_number", {
                required: "مطلوب",
                validate: (value) => (!/^FL-\d{9}$/.test(value) ? "يجب أن يبدأ بـ -FL ثم 9 أرقام" : true),
              })}
            />
            {errors.freelance_doc_number && <ErrorText>{errors.freelance_doc_number.message}</ErrorText>}
          </SingleColumn>
        </>
      ) : (
        ""
      )}
      {type === "person" ? (
        <SingleColumn>
          <Label htmlFor="gover_expire_date"> تاريخ إنتهاء {hasLisence == "yes" ? "الرخصة " : " الهوية"}</Label>
          <Controller
            name="gover_expire_date"
            control={control}
            defaultValue={calendars.gover_expire_date || null}
            rules={{
              required: "مطلوب",
              validate: (value) => value > new Date() || "يجب أن يكون التاريخ في المستقبل",
            }}
            render={({ field }) => (
              <CalendarWrapper>
                <Input
                  type="text"
                  {...field}
                  value={calendars.gover_expire_date ? calendars.gover_expire_date.toLocaleDateString() : ""}
                  onFocus={() => setShowCalendar(2)}
                  readOnly
                  placeholder="اختر تاريخ الانتهاء"
                />
                {showCalendar == 2 && (
                  <CalendarModal>
                    <Calendar
                      onChange={(date) => handleDateChange("gover_expire_date", date)}
                      value={calendars.gover_expire_date}
                      locale="ar"
                      minDate={new Date()}
                    />
                  </CalendarModal>
                )}
              </CalendarWrapper>
            )}
          />
          {errors.gover_expire_date && <ErrorText>{errors.gover_expire_date.message}</ErrorText>}
        </SingleColumn>
      ) : (
        ""
      )}

      <SingleColumn>
        <Label htmlFor="city_id">المدينة</Label>
        <Select id="city_id" {...register("city_id", { required: "مطلوب" })}>
          <option value="" disabled hidden>
            اختر المدينة
          </option>
          {cities?.map((c) => (
            <option value={c?.id} key={c?.id}>
              {c?.city_name}
            </option>
          ))}
        </Select>
        {errors.city_id && <ErrorText>{errors.city_id.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="mobile">رقم الجوال</Label>
        <Input
          type="number"
          id="mobile"
          {...register("mobile", {
            required: "مطلوب",
            pattern: {
              value: /^05\d{8}$/,
              message: "رقم الجوال يجب أن يبدأ بـ 05 ويتبعه 8 أرقام",
            },
          })}
        />
        {errors.mobile && <ErrorText>{errors.mobile.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="email">البريد الإلكتروني</Label>
        <Input
          type="email"
          id="email"
          {...register("email", {
            required: "مطلوب",
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: "البريد الإلكتروني غير صالح",
            },
          })}
        />
        {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="password">كلمة المرور</Label>
        <Input
          type="password"
          id="password"
          {...register("password", { required: "مطلوب", minLength: { value: 6, message: "كلمة المرور قصيرة" } })}
        />
        {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="confirmPassword">تأكيد كلمة المرور</Label>
        <Input
          type="password"
          id="confirmPassword"
          {...register("confirmPassword", {
            required: "مطلوب",
            validate: (value) => value === getValues("password") || "كلمة المرور غير متطابقة",
          })}
        />
        {errors.confirmPassword && <ErrorText>{errors.confirmPassword.message}</ErrorText>}
      </SingleColumn>

      {type === "person" ? (
        ""
      ) : (
        <>
          {type === "company" ? (
            <DoubleColumn>
              <Header>بيانات الشركة</Header>
            </DoubleColumn>
          ) : (
            ""
          )}
          <SingleColumn>
            <Label htmlFor="company_name">اسم الشركة او المؤسسة</Label>
            <Input type="text" id="company_name" {...register("company_name", { required: "مطلوب" })} />
            {errors.last && <ErrorText>{errors.last.message}</ErrorText>}
          </SingleColumn>
          <SingleColumn>
            <Label htmlFor="company_reg_number">الرقم الموحد للمنشأة</Label>
            <Input
              type="number"
              id="company_reg_number"
              {...register("company_reg_number", {
                required: "مطلوب",
                pattern: {
                  value: /^700\d{7}$/,
                  message: "الرقم الموحد يجب أن يبدأ بـ '700' ويليه 7 أرقام",
                },
              })}
            />
            {errors.company_reg_number && <ErrorText>{errors.company_reg_number.message}</ErrorText>}
          </SingleColumn>
        </>
      )}

      {type == "company" && (
        <SingleColumn>
          <Label htmlFor="gover_expire_date">تاريخ إنتهاء السجل التجاري</Label>
          <Controller
            name="gover_expire_date"
            control={control}
            defaultValue={calendars.gover_expire_date || null}
            rules={{
              required: "مطلوب",
              validate: (value) => value > new Date() || "يجب أن يكون التاريخ في المستقبل",
            }}
            render={({ field }) => (
              <CalendarWrapper>
                <Input
                  type="text"
                  {...field}
                  value={calendars.gover_expire_date ? calendars.gover_expire_date.toLocaleDateString() : ""}
                  onFocus={() => setShowCalendar(2)}
                  readOnly
                  placeholder="اختر تاريخ الانتهاء"
                />
                {showCalendar == 2 && (
                  <CalendarModal>
                    <Calendar
                      onChange={(date) => handleDateChange("gover_expire_date", date)}
                      value={calendars.gover_expire_date}
                      locale="ar"
                      minDate={new Date()}
                    />
                  </CalendarModal>
                )}
              </CalendarWrapper>
            )}
          />
          {errors.gover_expire_date && <ErrorText>{errors.gover_expire_date.message}</ErrorText>}
        </SingleColumn>
      )}
      {type === "person" && hasLisence == "yes" && <SingleColumn single></SingleColumn>}
      <SingleColumn>
        <Label htmlFor="iban_number">رقم الأيبان</Label>
        <Input
          type="text"
          id="iban_number"
          {...register("iban_number", {
            required: "مطلوب",
            pattern: {
              value: /^SA\d{22}$/,
              message: "رقم الأيبان يجب أن يبدأ بـ 'SA' ويليه 22 رقم",
            },
          })}
        />
        {errors.iban_number && <ErrorText>{errors.iban_number.message}</ErrorText>}
      </SingleColumn>

      <SingleColumn>
        <Label htmlFor="iban_photo">
          صورة شهادة الأيبان
          <SubLabel>
            {type == "person" ? " (يجب ان يطابق اسمك في الهوية مع اسم صاحب الحساب) " : " (يجب ان يطابق اسم الشركة) "}
          </SubLabel>
        </Label>
        <IbanPhotoWrapper>
          <HiddenInput
            type="file"
            id="iban_photo"
            name="iban_photo"
            {...register("iban_photo")}
            onChange={handleIbanPhotoChange}
          />
          <UploadButton onClick={() => document.getElementById("iban_photo").click()}>رفع الصورة</UploadButton>
          <FileName>{ibanPhoto || allData?.iban_photo?.name || "لم يتم اختيار صورة"}</FileName>
        </IbanPhotoWrapper>
        {errors.iban_photo && <ErrorText>{errors.iban_photo.message}</ErrorText>}
      </SingleColumn>
      <DoubleColumn>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="terms"
            {...register("terms", {
              required: "يجب قبول الشروط والأحكام للمتابعة",
            })}
            defaultChecked
          />
          <StyledCheckbox htmlFor="terms">{watch("terms") && <CheckIcon />}</StyledCheckbox>
          <LabelText htmlFor="terms">
            بإستكمال التسجيل فأنت توافق على الإلتزام بهذة{" "}
            <a href="/terms-conditions" target="_blank">
              الشروط والأحكام
            </a>
          </LabelText>
        </CheckboxContainer>
        {errors.terms && <ErrorText style={{ marginRight: "22px" }}>{errors.terms.message}</ErrorText>}
      </DoubleColumn>
      <DoubleColumn>
        <Button type="submit" onClick={() => setButtonAction("next")}>
          المتابعة
          <img src={line1} alt="" style={{ rotate: "-180deg" }} />
        </Button>
        <Button
          type="button"
          style={{ background: "#DEDEDE", color: "#202020", marginBottom: "0" }}
          onClick={() => handleBack("back")}
        >
          <img src={line2} alt="" style={{ rotate: "-180deg" }} />
          العودة
        </Button>
      </DoubleColumn>
    </FormWrapper>
  );
};

export default MemberForm;
