import styled from "styled-components";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const steps = [
  {
    title: "إنشاء حساب",
    description: "انشيء حسابك من خلال صفحة التسجيل",
  },
  {
    title: "تحميل التطبيق",
    description: "حمل تطبيق Busy Agent من متجر التطبيقات",
  },
  {
    title: "تحديث الملف الشخصي",
    description: "أضف معلومات حول المهارات والخبرات",
  },
  {
    title: "تصفح عروض العمل",
    description: "استعرض المشاريع المتاحة",
  },
  {
    title: "إرسال عروض الأسعار",
    description: "قدم عرضك وانتظر الموافقة لبدء العمل",
  },
  {
    title: "اسحب أرباحك",
    description: "قم بسحب أرباحك عند الانتهاء",
  },
];

const ApplyHow = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const Wrapper = styled.div`
    background: #e5f97f;
    margin: 40px auto;
    padding: 80px 20px 20px;

    @media (min-width: 768px) {
      padding-top: 60px;
      margin: 40px auto 120px;
    }

    @media (min-width: 1024px) {
      padding-top: 30px;
    }

    @media (min-width: 1280px) {
      padding-top: 60px;
      padding-bottom: 40px;
      padding-left: 134px;
      padding-right: 134px;
    }
    @media (min-width: 1580px) {
      padding-top: 130px;
      padding-bottom: 40px;
      padding-left: 134px;
      padding-right: 134px;
    }
  `;

  const Title2 = styled.h2`
    font-size: 24px;
    line-height: 28px;
    color: #0a1730;
    font-weight: 600;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 28px;
      text-align: right;
      line-height: 32px;
      margin-top: 70px;
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      font-size: 32px;
      line-height: 36px;
      margin-top: 100px;
      margin-bottom: 50px;
    }
    @media (min-width: 1580px) {
      margin-top: 50px;
    }
    @media (min-width: 1780px) {
      margin-top: 0;
    }
  `;

  const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
    margin-top: 30px;
    margin-bottom: 70px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;
      margin-bottom: 100px;
    }

    @media (min-width: 1224px) {
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 53px;
      margin-bottom: 140px;
    }
  `;

  const Service = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    @media (min-width: 768px) {
      gap: 30px;
    }

    @media (min-width: 1024px) {
      gap: 33px;
    }
  `;

  const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: numbers-font !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    color: #ffffff;
    background: #0a1730;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @media (min-width: 768px) {
      font-size: 24px;
      width: 45px;
      height: 45px;
    }

    @media (min-width: 1024px) {
      font-size: 26px;
      width: 47px;
      height: 47px;
    }
  `;

  const Title = styled.h4`
    font-size: 18px;
    font-weight: 500;
    color: #0a1730;

    @media (min-width: 768px) {
      font-size: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 24px;
    }
  `;

  const Desc = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #0a1730;
    opacity: 0.6;

    @media (min-width: 768px) {
      font-size: 15px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  `;

  return (
    <Wrapper>
      <Title2 data-aos="fade-up">كيف تقوم بالتسجيل كمقدم خدمة؟</Title2>
      <Container>
        {steps.map((step, index) => (
          <Service key={index} data-aos="fade-left">
            <Circle>{index + 1}</Circle>
            <div>
              <Title>{step.title}</Title>
              <Desc>{step.description}</Desc>
            </div>
          </Service>
        ))}
      </Container>
    </Wrapper>
  );
};

export default ApplyHow;
