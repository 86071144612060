import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px !important;

  a:first-of-type {
    margin-bottom: 16px;
  }
`;

const Medium = css`
  align-items: start;
  flex-direction: row;

  a:first-of-type {
    margin-bottom: 0;
    margin-left: 16px;
  }
`;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompDownloadAppLinks = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
