import AppleStoreIcon from "../../icons/AppleStoreIcon";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { SCompDownloadApp } from "./styles/SCompDownloadApp";
import { SCompDownloadAppContainer } from "./styles/SCompDownloadAppContainer";
import { SCompDownloadAppContent } from "./styles/SCompDownloadAppContent";
import { SCompDownloadAppImg } from "./styles/SCompDownloadAppImg";
import { SCompDownloadAppImgCont } from "./styles/SCompDownloadAppImgCont";
import { SCompDownloadAppLinks } from "./styles/SCompDownloadAppLinks";
import { SCompDownloadAppList } from "./styles/SCompDownloadAppList";
import { SCompDownloadAppListItem } from "./styles/SCompDownloadAppListItem";
import { SCompDownloadAppText } from "./styles/SCompDownloadAppText";
import { SCompDownloadAppTitle } from "./styles/SCompDownloadAppTitle";
import { SCompDownloadAppWrapper } from "./styles/SCompDownloadAppWrapper";
import image from "../../assets/images/sec2.png";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import right from "../../assets/images/tick-circle.svg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import click2 from "../../assets/images/click2.svg";

const servicesData = [
  {
    title: "خدمات متنوعة",
    desc: "يقدم مجموعة واسعة من الخدمات تناسب مختلف الاحتياجات، سواء للأفراد أو أصحاب المشاريع الصغيرة",
  },
  {
    title: "التحكم في الميزانية",
    desc: "يسمح للمستخدمين بتحديد ميزانية الخدمة وفق إمكانياتهم، مما يمنحهم مرونة مالية",
  },
  {
    title: "تقييمات موثوقة",
    desc: "يعرض تقييمات حقيقية من العملاء السابقين، مما يساعد في اتخاذ قرارات آمنة",
  },
  {
    title: "تنوع في العروض",
    desc: "يوفر خيارات متعددة تلبي تفضيلات واحتياجات المستخدمين المختلفة",
  },
  {
    title: "لأصحاب المشاريع الصغيرة",
    desc: "يتيح لهم الوصول إلى مستقلين في مختلف المجالات دون الحاجة إلى التوظيف الدائم",
  },
];

const CompDownloadApp = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const ImageStyle = styled.div`
    img {
      display: block;
      width: 100%;
      max-width: 471px;
      height: auto;
      border-radius: 10px;
      object-fit: contain;

      @media (max-width: 1420px) {
        img {
          width: 80%;

          max-width: 201px;
        }
      }
    }
  `;

  const SCompDownloadAppTitle2 = styled.h2`
    font-weight: 700;
    font-size: 46px;
    line-height: 1.3;
    color: #202020;
    margin-bottom: 20px;
    text-align: right;
    width: fit-content;

    @media (max-width: 1316px) {
      font-size: 38px;
      line-height: 1.4;
    }

    @media (max-width: 1024px) {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 15px;
      width: 100%;
    }

    @media (max-width: 480px) {
      font-weight: 500;
      font-size: 24px;
    }
  `;
  const Service = styled.div`
    display: flex;
    align-items: start;
    text-align: start;
    gap: 15px;
    margin: 20px 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 10px;
    }

    @media (max-width: 480px) {
      gap: 8px;
    }
  `;

  const RightIcon = styled.img`
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }

    @media (max-width: 480px) {
      width: 18px;
      height: 18px;
    }
  `;
  const ServiceTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 6px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  `;

  const ServiceDesc = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: #0a1730;
    font-weight: 400;
    opacity: 0.6;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
    }
  `;

  return (
    <SCompDownloadAppContainer>
      <SCompDownloadAppWrapper data-aos="fade-left">
        <SCompDownloadAppContent>
          <SCompDownloadAppTitle2>خدمات متنوعة وميزانية محددة :</SCompDownloadAppTitle2>
          <SCompDownloadAppList>
            {servicesData.map((service, index) => (
              <Service key={index} data-aos="fade-left" data-aos-delay={index * 100}>
                <RightIcon src={right} alt="" />
                <div>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDesc>{service.desc}</ServiceDesc>
                </div>
              </Service>
            ))}
          </SCompDownloadAppList>
          <ServiceDesc style={{ fontSize: "16px", fontWeight: "600" }}>حمل التطبيق لطلب الخدمة:</ServiceDesc>
          <SCompDownloadAppLinks style={{ marginTop: "12px" }}>
            <a target="_blank" href="https://apps.apple.com/sa/app/busy/id6444770742">
              <img src={apple} alt="apple" data-aos="fade-right" />
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.details.busy">
              <img src={google} alt="google" data-aos="fade-right" />
            </a>
          </SCompDownloadAppLinks>
        </SCompDownloadAppContent>
        <ImageStyle>
          <img src={image} alt="حمل التطبيق الأن !" data-aos="fade-left" />
        </ImageStyle>
      </SCompDownloadAppWrapper>
    </SCompDownloadAppContainer>
  );
};

export default CompDownloadApp;
