import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { SHero } from "./styles/SHero";
import { SHeroButtons } from "./styles/SHeroButtons";
import { SHeroContainer } from "./styles/SHeroContainer";
import { SHeroContent } from "./styles/SHeroContent";
import { SHeroImage } from "./styles/SHeroImage";
import { SHeroLines } from "./styles/SHeroLines";
import { SHeroNavigationButton } from "./styles/SHeroNavigationButton";
import { SHeroNavigationButtons } from "./styles/SHeroNavigationButtons";
import { SHeroText } from "./styles/SHeroText";
import { SHeroTitle } from "./styles/SHeroTitle";
import HeroImage from "../../assets/images/post1.gif";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
SwiperCore.use([Navigation]);

const Hero = () => {
  const [, setSwiperRef] = React.useState(null);
  const prevBtnRef = React.useRef(null);
  const nextBtnRef = React.useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
    AOS.refresh();
  }, []);

  const FindJobButton = styled.a`
    height: 56px;
    border-radius: 7px;
    border: 1px solid #0a1730;
    color: #0a1730;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-conent: center;
    align-items: center;
    padding: 0 42px;
  `;

  return (
    <SHero>
      <SHeroContainer>
        <SHeroImage>
          <img src={HeroImage} alt="heroImage" data-aos="zoom-out" />
        </SHeroImage>
        <SHeroContent>
          <div>
            <SHeroTitle data-aos="fade-left">نموذج جديد للعمل الحر المؤقت</SHeroTitle>
            <SHeroText data-aos="fade-left">
              يتيح للأفراد والشركات الوصول إلى شبكة واسعة من المهنيين المستقلين لتنفيذ مهام قصيرة الأجل أو مشاريع محددة
            </SHeroText>
            <SHeroButtons data-aos="fade-left">
              <FindJobButton href="#find-service" style={{ color: "#E5F97F", background: "#0A1730" }}>
                ابحث عن خدمة
              </FindJobButton>
              <FindJobButton href="#find-job">ابحث عن عمل</FindJobButton>
            </SHeroButtons>
            {/* <SHeroButtons>
              <a target="_blank" href="https://apps.apple.com/sa/app/busy/id6444770742">
                <img src={apple} alt="apple" data-aos="fade-right" />
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.details.busy">
                <img src={google} alt="google" data-aos="fade-right" />
              </a>
            </SHeroButtons>*/}
          </div>

          {/*    <SHeroNavigationButtons>
            <SHeroNavigationButton ref={prevBtnRef}>
              <svg width="46" height="8" viewBox="0 0 46 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M45.3536 4.35355C45.5488 4.15829 45.5488 3.84171 45.3536 3.64645L42.1716 0.464466C41.9763 0.269204 41.6597 0.269204 41.4645 0.464466C41.2692 0.659728 41.2692 0.976311 41.4645 1.17157L44.2929 4L41.4645 6.82843C41.2692 7.02369 41.2692 7.34027 41.4645 7.53553C41.6597 7.7308 41.9763 7.7308 42.1716 7.53553L45.3536 4.35355ZM0 4.5L45 4.5V3.5L0 3.5L0 4.5Z"
                  fill="black"
                />
              </svg>
            </SHeroNavigationButton>
            <SHeroNavigationButton ref={nextBtnRef}>
              <svg width="46" height="8" viewBox="0 0 46 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.646446 4.35355C0.451183 4.15829 0.451183 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM46 4.5L1 4.5V3.5L46 3.5V4.5Z"
                  fill="black"
                />
              </svg>
            </SHeroNavigationButton>
          </SHeroNavigationButtons>*/}
        </SHeroContent>
      </SHeroContainer>
    </SHero>
  );
};

export default Hero;

// <Swiper
// slidesPerView={1}
// spaceBetween={10}
// watchOverflow
// navigation={{
//   prevEl: prevBtnRef.current,
//   nextEl: nextBtnRef.current,
// }}
// onSwiper={setSwiperRef}
// >

// <SwiperSlide>
//   <SHeroTitle>
//     اجعل حياتك أكثر سهولة و راحة مع <span style={{ color: "#0A1730" }}>بيزي</span>
//   </SHeroTitle>
//   <SHeroText>
//     الوسيط المثالي بينك وبين صاحب المهارة التي تحتاجها ، اطلب الخدمة التي ترغب بها و سيقوم بيزي بتوصيلك
//     مباشرة مع الخبراء المؤهلين لتلبية احتياجاتك.{" "}
//   </SHeroText>
//   <SHeroButtons>
//     <a target="_blank" href="https://apps.apple.com/sa/app/busy-agent/id6502257280">
//       <img src={apple} alt="apple" data-aos="fade-right" />
//     </a>
//     <a target="_blank" href="https://play.google.com/store/apps/details?id=com.busy.agent">
//       <img src={google} alt="google" data-aos="fade-right" />
//     </a>
//   </SHeroButtons>
// </SwiperSlide>
// </Swiper>
