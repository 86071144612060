import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  font-weight: 400;
  font-size: 15px;
  line-height: 196%;
  color: rgba(27, 27, 27, 0.7);
  margin-top: 12px;
`;

const Medium = css`
  font-size: 18px;
`;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SHeroText = styled.p`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
