import AppleStoreIcon from "../../icons/AppleStoreIcon";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { SCompDownloadApp } from "./styles/SCompDownloadApp";
import { SCompDownloadAppContainer } from "./styles/SCompDownloadAppContainer";
import { SCompDownloadAppContent } from "./styles/SCompDownloadAppContent";
import { SCompDownloadAppImg } from "./styles/SCompDownloadAppImg";
import { SCompDownloadAppImgCont } from "./styles/SCompDownloadAppImgCont";
import { SCompDownloadAppLinks } from "./styles/SCompDownloadAppLinks";
import { SCompDownloadAppList } from "./styles/SCompDownloadAppList";
import { SCompDownloadAppListItem } from "./styles/SCompDownloadAppListItem";
import { SCompDownloadAppText } from "./styles/SCompDownloadAppText";
import { SCompDownloadAppTitle } from "./styles/SCompDownloadAppTitle";
import { SCompDownloadAppWrapper } from "./styles/SCompDownloadAppWrapper";
import image from "../../assets/images/home-page-worker-section.png";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import right from "../../assets/images/tick-circle.svg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import click2 from "../../assets/images/click2.svg";

const servicesData = [
  {
    title: "دخل إضافي",
    desc: "يساعد الأفراد والمشاريع الصغيرة على زيادة دخلهم وتوسيع قاعدة العملاء",
  },
  {
    title: "فرص عمل متنوعة",
    desc: "يتيح الوصول إلى مجموعة كبيرة من المشاريع الصغيرة والمهام المتنوعة البسيطة",
  },
  {
    title: "المرونة في العمل",
    desc: "يسمح باختيار المشاريع حسب التفضيلات الشخصية، ما يحقق التوازن بين الحياة المهنية والشخصية.",
  },
  {
    title: "معاملات مالية آمنة",
    desc: "يوفر آليات دفع موثوقة وعقود إلكترونية تحفظ حقوق مقدم الخدمة وضمان استلام الأجور",
  },
  {
    title: "عمولة صفرية",
    desc: "لا يوجد أي عمولات على الأرباح، مما يسمح لمقدمي الخدمات بالاحتفاظ بالأرباح كاملة",
  },
  {
    title: "تطوير السمعة المهنية",
    desc: "يتيح نظام التقييمات للعملاء تقديم ملاحظاتهم، مما يساعد في بناء سمعة قوية وجذب فرص جديدة",
  },
];

const CompDownloadApp = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const ImageStyle = styled.div`
    width: 100%;
    heghit: 100%;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 70%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 1280px) {
      img {
        position: relative;
        left: auto;
        width: 100%;
      }
    }
    @media (max-width: 1180px) {
      img {
        max-height: 550px;
      }
    }
    @media (max-width: 767px) {
      img {
        left: 12%;
        width: 120%;
        background: #dff37d;
      }
    }
  `;
  const SCompDownloadAppTitle2 = styled.h2`
    font-weight: 700;
    font-size: 46px;
    line-height: 1.3;
    color: #202020;
    margin-bottom: 20px;
    text-align: right;
    width: fit-content;

    @media (max-width: 1316px) {
      font-size: 38px;
      line-height: 1.4;
    }

    @media (max-width: 1024px) {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 15px;
      width: 100%;
    }

    @media (max-width: 480px) {
      font-weight: 500;
      font-size: 24px;
    }
  `;

  const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 248px;
    height: 52px;
    border-radius: 7px;
    background: #e5f97f;
    font-size: 15px;
    font-weight: 700;
    color: #202020;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      background: #ecfaa4;
      transform: translateY(-2px);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
      width: 208px;
      height: 48px;
      font-size: 14px;
      margin: 20px auto 0;
    }
  `;

  const Service = styled.div`
    display: flex;
    align-items: start;
    text-align: start;
    gap: 15px;
    margin: 20px 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 10px;
    }

    @media (max-width: 480px) {
      gap: 8px;
    }
  `;

  const RightIcon = styled.img`
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  `;

  const ServiceTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 6px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  `;

  const ServiceDesc = styled.p`
    font-size: 14px;
    line-height: 20px;
    color: #0a1730;
    font-weight: 400;
    opacity: 0.6;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
    }
  `;

  return (
    <SCompDownloadAppContainer>
      <SCompDownloadAppWrapper className="assign" data-aos="fade-left">
        <ImageStyle>
          <img src={image} alt="حمل التطبيق الأن !" data-aos="zoom-out-left" data-aos-duration="4000" />
        </ImageStyle>
        <SCompDownloadAppContent>
          <SCompDownloadAppTitle2>دخل إضافي وعمولة صفرية :</SCompDownloadAppTitle2>
          <SCompDownloadAppList>
            {servicesData.map((service, index) => (
              <Service key={index} data-aos="fade-left" data-aos-delay={index * 100}>
                <RightIcon src={right} alt="" />
                <div>
                  <ServiceTitle>{service.title}</ServiceTitle>
                  <ServiceDesc>{service.desc}</ServiceDesc>
                </div>
              </Service>
            ))}
          </SCompDownloadAppList>
          <Button data-aos="fade-up" href="/agent-registration">
            <img src={click2} alt="Icon" />
            سجل الأن كمقدم خدمة
          </Button>
        </SCompDownloadAppContent>
      </SCompDownloadAppWrapper>
    </SCompDownloadAppContainer>
  );
};

export default CompDownloadApp;
