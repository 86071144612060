import styled from "styled-components";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import click from "../../assets/images/click.svg";

const Header = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const Container = styled.section`
    width: 100%;
    height: 250px;
    background: #e5f97f;
    display: flex;
    align-items: center;
    padding: 0 20px;

    @media (min-width: 768px) {
      padding: 0 60px;
    }

    @media (min-width: 1024px) {
      padding: 0 100px;
    }

    @media (min-width: 1280px) {
      padding: 0 134px;
    }
  `;

  const Head = styled.h1`
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: #202020;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media (min-width: 1024px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media (min-width: 1280px) {
      font-size: 40px;
      line-height: 48px;
    }
  `;

  const Para = styled.p`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 22.5px;
    }
  `;

  return (
    <Container>
      <div>
        <Head data-aos="fade-up">التسجيل كمقدم خدمة</Head>
        <Para data-aos="fade-up" data-aos-delay="100">
          التسجيل كمقدم خدمة يمكنك من البحث عن فرص العمل وتقديم العروض
        </Para>
      </div>
    </Container>
  );
};

export default Header;
