import styled from "styled-components";
import icon1 from "../../assets/images/sol1.png";
import icon2 from "../../assets/images/sol2.png";
import icon3 from "../../assets/images/sol3.png";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Container = styled.section`
  width: 100%;
  background-color: #e5f97f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #0a1730;
  padding: 20px;
  box-sizing: border-box;
  height:660px;

 
  @media (min-width:480px) {
    height: 689px;
  }
  @media (min-width: 767px) {
    height: 689px;
  }
  @media (min-width: 1024px) {
    height: 525px;
  }

  h2 {
      font-weight: 500;
    font-size: 46px; 
    line-height: 1.3;
    color: #202020;
    margin-bottom: 20px;
    text-align: right;

    @media (max-width: 1200px) {
      font-size: 42px;
      line-height: 1.4;
    }

    @media (max-width: 1024px) {
      font-size: 36px;
      text-align: center;
    }

    @media (max-width: 768px) {
      font-size: 28px; 
      line-height: 1.2;
      margin-bottom: 15px;
    }

    @media (max-width: 480px) {
      font-size: 24px; 
    }
  }

  p {
    margin-top: 6px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: normal;
    color: #202020;
    margin-bottom: 30px;

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 1.4;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 1.3;
    }

    @media (max-width: 480px) {
      font-size: 12px
      line-height: 1.2;
    }
  }

  .solution-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
    padding: 10px 10px 0;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .solution-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      opacity: 0;
      transition: opacity 0.5s ease;

      img {
        width: 80px; 
        height: 80px;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
          width: 60px;
          height: 60px;
        }

        @media (max-width: 480px) {
          width: 50px; 
          height: 50px;
        }
      }

      h3 {
        font-weight: 500;
        font-size: 24px; 
        color: #202020;

        @media (max-width: 1024px) {
          font-size: 20px; 
        }

        @media (max-width: 768px) {
          font-size: 18px;
        }

        @media (max-width: 480px) {
          font-size: 16px;
        }
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        color: #202020;

        @media (max-width: 1024px) {
          font-size: 14px; 
          line-height: 1.4;
        }

        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 1.3;
        }

        @media (max-width: 480px) {
          font-size: 10px;
          line-height: 1.2;
        }
      }

      &.aos-animate {
        opacity: 1;
      }
    }
  }
`;

const Solution = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <Container>
      <h2 data-aos="fade-up">بيزي الحل المثالي</h2>
      <p data-aos="fade-up" data-aos-delay="100">
        يخدم كل أطراف العلاقة مقدمي الخدمات وطالبيها
      </p>
      <div className="solution-grid">
        <div className="solution-item" data-aos="zoom-in" data-aos-delay="200">
          <img src={icon1} alt="دخل إضافي" />
          <h3>دخل إضافي</h3>
          <p>للأفراد وأصحاب المشاريع الصغيرة</p>
        </div>
        <div className="solution-item" data-aos="zoom-in" data-aos-delay="400">
          <img src={icon2} alt="أسعار مناسبة" />
          <h3>أسعار مناسبة</h3>
          <p>فقط حدد ميزانيتك وانتظر العروض</p>
        </div>
        <div className="solution-item" data-aos="zoom-in" data-aos-delay="600">
          <img src={icon3} alt="خدمات سريعة" />
          <h3>خدمات سريعة</h3>
          <p>تغنيك عن عناء البحث بالطرق التقليدية</p>
        </div>
      </div>
    </Container>
  );
};

export default Solution;
