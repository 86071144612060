import React from "react";
import styled from "styled-components";
import company from "../../assets/images/comapnies.svg";
import members from "../../assets/images/individiulas.svg";
import line1 from "../../assets/images/arrow_left_line.svg";
import toast, { Toaster } from "react-hot-toast";

const Type = ({ type, setType, handleChangeTab, setAllData, allData }) => {
  const Container = styled.div`
    display: flex;
    gap: 20px;
    margin: 20px 0 50px;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 36px;
      margin: 37px 0 70px;
    }
  `;

  const Rectangle = styled.div`
    width: 260px;
    height: 110px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    border: 2px solid transparent;
    cursor: pointer;
    gap: 10px;
    font-size: 16px;

    &:hover {
      background-color: #e0e0e0;
    }

    @media (min-width: 768px) {
      width: 300px;
      height: 124px;
      font-size: 18px;
    }
  `;

  const Button = styled.button`
    height: 52px;
    border-radius: 7px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #202020;
    color: #e5f97f;
    border: 0;
    margin: 33px 0;
    cursor: pointer;
  `;

  const handleNext = () => {
    if (type) {
      handleChangeTab("next");
    } else {
      toast.error("اختر بين أفراد أو شركات");
    }
  };

  return (
    <>
      {!type && <Toaster position="top-center" reverseOrder={false} />}
      <Container>
        <Rectangle
          style={{ borderColor: type === 1 ? "#E5F97F" : "transparent" }}
          onClick={() => {
            setType(1);
            setAllData({ ...allData, provider: "person" });
          }}
        >
          <img src={members} alt="Individuals icon" />
          أفراد
        </Rectangle>
        <Rectangle
          style={{ borderColor: type === 2 ? "#E5F97F" : "transparent" }}
          onClick={() => {
            setType(2);
            setAllData({ ...allData, provider: "company" });
          }}
        >
          <img src={company} alt="Companies icon" />
          شركات
        </Rectangle>
      </Container>
      <Button onClick={handleNext}>
        المتابعة
        <img src={line1} alt="" style={{ rotate: "-180deg" }} />
      </Button>
    </>
  );
};

export default Type;
