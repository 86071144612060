export const homeAdvantagesComponent = {
  data: [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
          <g clip-path="url(#clip0_87_4441)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.30973 27.6657C11.1109 27.5994 16.2117 26.8952 21.5534 21.5534C26.8952 16.2117 27.5994 11.1108 27.6657 9.30965C27.7667 6.56532 25.6644 3.89884 23.2349 2.85762C22.6649 2.6133 21.9744 2.65801 21.4285 3.05617C19.43 4.51395 18.0492 6.718 16.8639 8.45214C16.3284 9.2355 16.4564 10.3051 17.1837 10.9347L19.6227 12.746C19.8644 12.9255 19.9448 13.2518 19.7998 13.5157C19.248 14.5202 18.2649 16.0149 17.14 17.1399C16.0137 18.266 14.4484 19.3125 13.3749 19.9265C13.0965 20.0858 12.7464 19.9935 12.5704 19.7254L10.9837 17.3087C10.3754 16.4989 9.23911 16.322 8.41323 16.8937C6.65386 18.1113 4.60161 19.4672 3.09886 21.3917C2.67198 21.9384 2.60848 22.6534 2.86311 23.2477C3.90911 25.6884 6.55611 27.767 9.30973 27.6657Z"
              fill="#0A1730"
            />
          </g>
          <defs>
            <clipPath id="clip0_87_4441">
              <rect width="30" height="30" fill="white" transform="matrix(-1 0 0 1 30 0)" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "خدمة علي مدار اليوم",
      text: "التطبيق متاح كل الأوقات 24/7  وأيام العطلات ، بإمكانك طلب خدماتك في الوقت المناسب لك بالإتفاق مع أصحاب المهارات.",
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
          <g clip-path="url(#clip0_87_4466)">
            <path
              d="M30.7456 5.62538C30.45 5.05488 29.9576 4.61087 29.3597 4.37562C25.3753 2.83034 21.4573 1.35546 17.6377 0.09706C17.2497 -0.0323533 16.8303 -0.0323533 16.4423 0.09706C12.6233 1.35214 8.70602 2.82436 4.72231 4.36765C4.19727 4.57646 3.7525 4.94726 3.45263 5.42616C3.47454 5.42948 2.63317 8.05585 2.4904 13.8598C2.40208 19.6564 3.12458 21.3955 3.10067 21.3975C3.49114 22.6719 4.22294 23.9694 5.27216 25.2883C6.44423 26.7612 8.02802 28.2825 9.94051 29.7787C13.1081 32.2643 16.0718 33.7498 16.196 33.8022C16.4594 33.9328 16.7493 34.0008 17.0433 34.0008C17.3373 34.0008 17.6273 33.9328 17.8907 33.8022C18.0155 33.7498 20.9825 32.2596 24.1481 29.7747C26.0613 28.2779 27.6457 26.7565 28.8185 25.2843C29.9421 23.8718 30.7018 22.4859 31.069 21.1246C31.0544 21.1246 31.5856 18.9923 31.5119 14.1101C31.4183 9.23058 30.7383 5.62804 30.7456 5.62538Z"
              fill="#0A1730"
            />
            <path
              d="M25.3672 13.7013C22.5562 16.5959 19.6669 19.6267 16.8353 22.6183C16.6055 22.8623 16.3281 23.0567 16.0203 23.1895C15.7126 23.3223 15.3809 23.3908 15.0457 23.3906H15.0344C14.6989 23.3868 14.3675 23.3161 14.0596 23.1827C13.7518 23.0493 13.4736 22.8559 13.2414 22.6137C11.7253 21.0445 10.1987 19.4786 8.68194 17.9426C7.71175 16.9625 7.74163 15.4192 8.74436 14.461C9.7471 13.5027 11.3276 13.4974 12.2865 14.4816C13.1878 15.4046 14.0925 16.3343 15.0005 17.2706C17.2503 14.9105 19.5088 12.5319 21.7075 10.2209C22.6419 9.23745 24.2037 9.2494 25.2204 10.2209C26.2371 11.1924 26.3088 12.7311 25.3672 13.7013Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_87_4466">
              <rect width="34" height="34" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "حفظ الخصوصية",
      text: "دائماً مع BusyApp نضع حماية خصوصيتك في صميم اهتمامنا ، نضمن سرية بياناتك ومعلوماتك الشخصية وتأمينها بأعلي المعايير. ",
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
          <g clip-path="url(#clip0_87_4453)">
            <path
              d="M29.75 22.8692V24.7917C29.75 25.945 29.2098 26.9321 28.4613 27.7063C27.7192 28.4739 26.7139 29.1003 25.5817 29.5956C23.3129 30.5882 20.2777 31.1667 17 31.1667C13.7223 31.1667 10.6871 30.5882 8.4183 29.5956C7.28602 29.1003 6.28076 28.4739 5.53878 27.7063C4.8526 26.9966 4.34148 26.108 4.26106 25.0765L4.25 24.7917V22.8691C4.9094 23.2432 5.61339 23.5745 6.36629 23.8533C9.24209 24.9178 13.0033 25.5123 17.0001 25.5123C20.997 25.5123 24.7581 24.9178 27.6339 23.8533C28.1985 23.6442 28.7357 23.4057 29.2471 23.1416L29.75 22.8692ZM4.25 15.0774C4.9094 15.4516 5.61339 15.7828 6.36629 16.0616C9.24209 17.1261 13.0033 17.7207 17.0001 17.7207C20.997 17.7207 24.7581 17.1261 27.6339 16.0616C28.3867 15.7828 29.0907 15.4517 29.75 15.0776V19.4759C28.8307 20.181 27.8058 20.7683 26.6503 21.1962C24.1635 22.1167 20.7508 22.679 17.0001 22.679C13.2495 22.679 9.83667 22.1167 7.34994 21.1962C6.19436 20.7683 5.16933 20.1808 4.25 19.4758V15.0774ZM17 4.25C20.2777 4.25 23.3129 4.82845 25.5817 5.82104C26.7139 6.31641 27.7192 6.94284 28.4613 7.71032C29.1474 8.42009 29.6585 9.30864 29.7389 10.3402L29.75 10.625V11.6843C28.8307 12.3893 27.8058 12.9767 26.6503 13.4044C24.1635 14.325 20.7508 14.8873 17.0001 14.8873C13.2495 14.8873 9.83667 14.325 7.34994 13.4044C6.35944 13.0378 5.46486 12.5538 4.65054 11.979L4.25 11.6841V10.625C4.25 9.47168 4.79022 8.48462 5.53878 7.71032C6.28076 6.94284 7.28602 6.31641 8.4183 5.82104C10.6871 4.82845 13.7223 4.25 17 4.25Z"
              fill="#0A1730"
            />
          </g>
          <defs>
            <clipPath id="clip0_87_4453">
              <rect width="34" height="34" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      title: "أفضل الأسعار",
      text: "استمتع بأفضل الأسعار والقيمة الممتازة ، ستجد العروض الأكثر تنافسية سواء كنت صاحب مهارة ترغب في تقديم خدماتك أو طالب خدمة يبحث عن أفضل العروض.",
    },
  ],
};

export const contactUsAdvantagesComponent = {
  variant: "two",
  data: [
    {
      icon: (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.30973 27.6657C11.1109 27.5994 16.2117 26.8952 21.5534 21.5534C26.8952 16.2117 27.5994 11.1108 27.6657 9.30965C27.7667 6.56532 25.6644 3.89884 23.2349 2.85762C22.6649 2.6133 21.9744 2.65801 21.4285 3.05617C19.43 4.51395 18.0492 6.718 16.8639 8.45214C16.3284 9.2355 16.4564 10.3051 17.1837 10.9347L19.6227 12.746C19.8644 12.9255 19.9448 13.2518 19.7998 13.5157C19.248 14.5202 18.2649 16.0149 17.14 17.1399C16.0137 18.266 14.4484 19.3125 13.3749 19.9265C13.0965 20.0858 12.7464 19.9935 12.5704 19.7254L10.9837 17.3087C10.3754 16.4989 9.23911 16.322 8.41323 16.8937C6.65386 18.1113 4.60161 19.4672 3.09886 21.3917C2.67198 21.9384 2.60848 22.6534 2.86311 23.2477C3.90911 25.6884 6.55611 27.767 9.30973 27.6657Z"
            fill="#0A1730"
          />
        </svg>
      ),
      title: "رقم التليفون",
      text: "920014903",
      style: { textAlign: "left", direction: "ltr", width: "fit-content", marginLeft: "auto" },
    },
    {
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M22.6667 4C24.797 4 26.5383 5.66523 26.66 7.76497L26.6667 8V13.8001L27.4586 13.4483C28.2769 13.0846 29.2218 13.6489 29.3243 14.5115L29.3334 14.6667V25.3333C29.3334 26.7392 28.2456 27.8909 26.8658 27.9927L26.6667 28H5.33341C3.9276 28 2.77586 26.9122 2.67406 25.5324L2.66675 25.3333V14.6667C2.66675 13.7712 3.56626 13.1369 4.39608 13.3936L4.5416 13.4483L5.33341 13.8001V8C5.33341 5.86976 6.99865 4.12845 9.09838 4.00679L9.33341 4H22.6667ZM22.6667 6.66667H9.33341C8.59704 6.66667 8.00008 7.26363 8.00008 8V14.9853L16.0001 18.5409L24.0001 14.9853V8C24.0001 7.26363 23.4031 6.66667 22.6667 6.66667ZM16.0001 10.6667C16.7365 10.6667 17.3334 11.2636 17.3334 12C17.3334 12.6838 16.8187 13.2473 16.1556 13.3244L16.0001 13.3333H13.3334C12.597 13.3333 12.0001 12.7364 12.0001 12C12.0001 11.3162 12.5148 10.7527 13.1779 10.6756L13.3334 10.6667H16.0001Z"
            fill="#0A1730"
          />
        </svg>
      ),
      title: "البريد الإلكتروني",
      text: "info@busy.com.sa",
    },
    {
      icon: (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.4037 21.4741C24.0154 21.1683 24.7495 21.3766 25.115 21.9353L25.1925 22.0704L27.8592 27.4037C28.0659 27.817 28.0439 28.3078 27.8009 28.7009C27.5883 29.0448 27.2332 29.2711 26.8381 29.3222L26.6667 29.3333H5.33333C4.87123 29.3333 4.44208 29.094 4.19914 28.7009C3.98656 28.357 3.94308 27.9382 4.07397 27.562L4.14076 27.4037L6.80743 22.0704C7.13675 21.4117 7.93766 21.1448 8.59628 21.4741C9.20788 21.7799 9.48176 22.4922 9.25409 23.1198L9.19258 23.2629L7.49072 26.6666H24.5093L22.8075 23.2629C22.4781 22.6042 22.7451 21.8034 23.4037 21.4741ZM16 2.66663C21.1547 2.66663 25.3333 6.84531 25.3333 12C25.3333 15.1758 23.6151 17.7562 21.8365 19.5866C20.7417 20.7134 19.5678 21.6149 18.6126 22.2637L18.115 22.5924L17.6715 22.87C17.6027 22.912 17.5366 22.9518 17.4734 22.9894L17.1312 23.1885C16.4263 23.5889 15.5737 23.5889 14.8688 23.1885L14.5266 22.9894L14.1142 22.7377C14.0402 22.6914 13.9637 22.643 13.885 22.5924L13.3874 22.2637C12.4322 21.6149 11.2583 20.7134 10.1635 19.5866C8.38491 17.7562 6.66667 15.1758 6.66667 12C6.66667 6.84531 10.8453 2.66663 16 2.66663ZM16 9.33329C14.5272 9.33329 13.3333 10.5272 13.3333 12C13.3333 13.4728 14.5272 14.6666 16 14.6666C17.4728 14.6666 18.6667 13.4728 18.6667 12C18.6667 10.5272 17.4728 9.33329 16 9.33329Z"
            fill="#0A1730"
          />
        </svg>
      ),
      title: "العنوان",
      text: "شارع عمر بن الخطاب، الدمام - المنطقة الشرقية",
    },
  ],
};

export const privacyPolicyData = [
  {
    id: 1,
    tabName: "سياسة الإستخدام",
    tabContent: (
      <>
        <h3>سياسة الإستخدام</h3>
        <p>
          يُمكن القول أن كُل شيء يَتغير مع مرور الوقت، حيث أصبحت معظم الأمور الآن تَسير في طُرق مُختلفة عمَّا كانت عليه
          في العقود الماضيَة، حيث أن أصبح مُعظم اعتماد الأشخاص حاليّاً على التكنولوجيا، ويُمكن تلخيص أهم الجوانب التي
          اختلفت من حيث التكنولوجيا ما بين الماضي والحاضر هي:[٣] الاتصالات (بالإنجليزية: Communication): لقد كانت عمليات
          الاتصالات سابِقاً مُعتمِدة على البريد الكسول (بالإنجليزية: Snail mail)، حيث قد كان شائِعاً بين الأصدقاء
          المُراسلة عبر البريد الكسول، وكِتابة الرسائل يَدويَّاً مع وجود طواِبع بريدية عليها، وتُعد طريق المُراسة في
          البريد طريقة بطيئة وغير مُجدية النَّفع، أمَّا في وقتنا الحالي فقد أصبحت وسائل الاتصالات أكثر نفعاً وبِسرعة
          أكبر حيث تمَّ تَقصير وقت الاتصال من عِدَّة أيَّام إلى مجرد دقائق معدودة، وقد أصبح الاعتماد في هذه الأيام على
          كُل مِن البريد الإلكتروني أو الرسائل النصية أو الدردشة المرئية أو الفاكس أو الرسائل الفورية، وغيرها من
          التقنيات التي تسمح للأشخاص بالاتصال ببعضهم البعض دون الحاجة إلى العثور على صندوق بريد. وسائل النقل
          (بالإنجليزية: Transportation): لقد كانت وسائل النقل سابِقاُ غير مُريحة، حيث كانت أريح وسائل النقل هي العربات
          التي تجرها الخيول والسُفن، أمَّا الآن فإن جميع وسائل النقل تتمتَّع بالسرعة والأمان والراحة كاستخدام السيارات
          الحديثة أو الطائرات وغيرها. وسائل الترفيه (بالإنجليزية: Entertainment): لقد كانت المسرحيات .
        </p>
        <h3>سياسة الإستخدام</h3>
        <p>
          يُمكن القول أن كُل شيء يَتغير مع مرور الوقت، حيث أصبحت معظم الأمور الآن تَسير في طُرق مُختلفة عمَّا كانت عليه
          في العقود الماضيَة، حيث أن أصبح مُعظم اعتماد الأشخاص حاليّاً على التكنولوجيا، ويُمكن تلخيص أهم الجوانب التي
          اختلفت من حيث التكنولوجيا ما بين الماضي والحاضر هي:[٣] الاتصالات (بالإنجليزية: Communication): لقد كانت عمليات
          الاتصالات سابِقاً مُعتمِدة على البريد الكسول (بالإنجليزية: Snail mail)، حيث قد كان شائِعاً بين الأصدقاء
          المُراسلة عبر البريد الكسول، وكِتابة الرسائل يَدويَّاً مع وجود طواِبع بريدية عليها، وتُعد طريق المُراسة في
          البريد طريقة بطيئة وغير مُجدية النَّفع، أمَّا في وقتنا الحالي فقد أصبحت وسائل الاتصالات أكثر نفعاً وبِسرعة
          أكبر حيث تمَّ تَقصير وقت الاتصال من عِدَّة أيَّام إلى مجرد دقائق معدودة، وقد أصبح الاعتماد في هذه الأيام على
          كُل مِن البريد الإلكتروني أو الرسائل النصية أو الدردشة المرئية أو الفاكس أو الرسائل الفورية، وغيرها من
          التقنيات التي تسمح للأشخاص بالاتصال ببعضهم البعض دون الحاجة إلى العثور على صندوق بريد. وسائل النقل
          (بالإنجليزية: Transportation): لقد كانت وسائل النقل سابِقاُ غير مُريحة، حيث كانت أريح وسائل النقل هي العربات
          التي تجرها الخيول والسُفن، أمَّا الآن فإن جميع وسائل النقل تتمتَّع بالسرعة والأمان والراحة كاستخدام السيارات
          الحديثة أو الطائرات وغيرها. وسائل الترفيه (بالإنجليزية: Entertainment): لقد كانت المسرحيات .
        </p>
      </>
    ),
  },
  {
    id: 2,
    tabName: "إلغاء الحساب",
    tabContent: (
      <>
        <h3>سياسة الإستخدام2</h3>
        <p>
          يُمكن القول أن كُل شيء يَتغير مع مرور الوقت، حيث أصبحت معظم الأمور الآن تَسير في طُرق مُختلفة عمَّا كانت عليه
          في العقود الماضيَة، حيث أن أصبح مُعظم اعتماد الأشخاص حاليّاً على التكنولوجيا، ويُمكن تلخيص أهم الجوانب التي
          اختلفت من حيث التكنولوجيا ما بين الماضي والحاضر هي:[٣] الاتصالات (بالإنجليزية: Communication): لقد كانت عمليات
          الاتصالات سابِقاً مُعتمِدة على البريد الكسول (بالإنجليزية: Snail mail)، حيث قد كان شائِعاً بين الأصدقاء
          المُراسلة عبر البريد الكسول، وكِتابة الرسائل يَدويَّاً مع وجود طواِبع بريدية عليها، وتُعد طريق المُراسة في
          البريد طريقة بطيئة وغير مُجدية النَّفع، أمَّا في وقتنا الحالي فقد أصبحت وسائل الاتصالات أكثر نفعاً وبِسرعة
          أكبر حيث تمَّ تَقصير وقت الاتصال من عِدَّة أيَّام إلى مجرد دقائق معدودة، وقد أصبح الاعتماد في هذه الأيام على
          كُل مِن البريد الإلكتروني أو الرسائل النصية أو الدردشة المرئية أو الفاكس أو الرسائل الفورية، وغيرها من
          التقنيات التي تسمح للأشخاص بالاتصال ببعضهم البعض دون الحاجة إلى العثور على صندوق بريد. وسائل النقل
          (بالإنجليزية: Transportation): لقد كانت وسائل النقل سابِقاُ غير مُريحة، حيث كانت أريح وسائل النقل هي العربات
          التي تجرها الخيول والسُفن، أمَّا الآن فإن جميع وسائل النقل تتمتَّع بالسرعة والأمان والراحة كاستخدام السيارات
          الحديثة أو الطائرات وغيرها. وسائل الترفيه (بالإنجليزية: Entertainment): لقد كانت المسرحيات .
        </p>
        <h3>سياسة الإستخدام</h3>
        <p>
          يُمكن القول أن كُل شيء يَتغير مع مرور الوقت، حيث أصبحت معظم الأمور الآن تَسير في طُرق مُختلفة عمَّا كانت عليه
          في العقود الماضيَة، حيث أن أصبح مُعظم اعتماد الأشخاص حاليّاً على التكنولوجيا، ويُمكن تلخيص أهم الجوانب التي
          اختلفت من حيث التكنولوجيا ما بين الماضي والحاضر هي:[٣] الاتصالات (بالإنجليزية: Communication): لقد كانت عمليات
          الاتصالات سابِقاً مُعتمِدة على البريد الكسول (بالإنجليزية: Snail mail)، حيث قد كان شائِعاً بين الأصدقاء
          المُراسلة عبر البريد الكسول، وكِتابة الرسائل يَدويَّاً مع وجود طواِبع بريدية عليها، وتُعد طريق المُراسة في
          البريد طريقة بطيئة وغير مُجدية النَّفع، أمَّا في وقتنا الحالي فقد أصبحت وسائل الاتصالات أكثر نفعاً وبِسرعة
          أكبر حيث تمَّ تَقصير وقت الاتصال من عِدَّة أيَّام إلى مجرد دقائق معدودة، وقد أصبح الاعتماد في هذه الأيام على
          كُل مِن البريد الإلكتروني أو الرسائل النصية أو الدردشة المرئية أو الفاكس أو الرسائل الفورية، وغيرها من
          التقنيات التي تسمح للأشخاص بالاتصال ببعضهم البعض دون الحاجة إلى العثور على صندوق بريد. وسائل النقل
          (بالإنجليزية: Transportation): لقد كانت وسائل النقل سابِقاُ غير مُريحة، حيث كانت أريح وسائل النقل هي العربات
          التي تجرها الخيول والسُفن، أمَّا الآن فإن جميع وسائل النقل تتمتَّع بالسرعة والأمان والراحة كاستخدام السيارات
          الحديثة أو الطائرات وغيرها. وسائل الترفيه (بالإنجليزية: Entertainment): لقد كانت المسرحيات .
        </p>
      </>
    ),
  },
  {
    id: 3,
    tabName: "حفظ المعلومات",
    tabContent: (
      <>
        <h3>سياسة الإستخدام3</h3>
        <p>
          يُمكن القول أن كُل شيء يَتغير مع مرور الوقت، حيث أصبحت معظم الأمور الآن تَسير في طُرق مُختلفة عمَّا كانت عليه
          في العقود الماضيَة، حيث أن أصبح مُعظم اعتماد الأشخاص حاليّاً على التكنولوجيا، ويُمكن تلخيص أهم الجوانب التي
          اختلفت من حيث التكنولوجيا ما بين الماضي والحاضر هي:[٣] الاتصالات (بالإنجليزية: Communication): لقد كانت عمليات
          الاتصالات سابِقاً مُعتمِدة على البريد الكسول (بالإنجليزية: Snail mail)، حيث قد كان شائِعاً بين الأصدقاء
          المُراسلة عبر البريد الكسول، وكِتابة الرسائل يَدويَّاً مع وجود طواِبع بريدية عليها، وتُعد طريق المُراسة في
          البريد طريقة بطيئة وغير مُجدية النَّفع، أمَّا في وقتنا الحالي فقد أصبحت وسائل الاتصالات أكثر نفعاً وبِسرعة
          أكبر حيث تمَّ تَقصير وقت الاتصال من عِدَّة أيَّام إلى مجرد دقائق معدودة، وقد أصبح الاعتماد في هذه الأيام على
          كُل مِن البريد الإلكتروني أو الرسائل النصية أو الدردشة المرئية أو الفاكس أو الرسائل الفورية، وغيرها من
          التقنيات التي تسمح للأشخاص بالاتصال ببعضهم البعض دون الحاجة إلى العثور على صندوق بريد. وسائل النقل
          (بالإنجليزية: Transportation): لقد كانت وسائل النقل سابِقاُ غير مُريحة، حيث كانت أريح وسائل النقل هي العربات
          التي تجرها الخيول والسُفن، أمَّا الآن فإن جميع وسائل النقل تتمتَّع بالسرعة والأمان والراحة كاستخدام السيارات
          الحديثة أو الطائرات وغيرها. وسائل الترفيه (بالإنجليزية: Entertainment): لقد كانت المسرحيات .
        </p>
        <h3>سياسة الإستخدام</h3>
        <p>
          يُمكن القول أن كُل شيء يَتغير مع مرور الوقت، حيث أصبحت معظم الأمور الآن تَسير في طُرق مُختلفة عمَّا كانت عليه
          في العقود الماضيَة، حيث أن أصبح مُعظم اعتماد الأشخاص حاليّاً على التكنولوجيا، ويُمكن تلخيص أهم الجوانب التي
          اختلفت من حيث التكنولوجيا ما بين الماضي والحاضر هي:[٣] الاتصالات (بالإنجليزية: Communication): لقد كانت عمليات
          الاتصالات سابِقاً مُعتمِدة على البريد الكسول (بالإنجليزية: Snail mail)، حيث قد كان شائِعاً بين الأصدقاء
          المُراسلة عبر البريد الكسول، وكِتابة الرسائل يَدويَّاً مع وجود طواِبع بريدية عليها، وتُعد طريق المُراسة في
          البريد طريقة بطيئة وغير مُجدية النَّفع، أمَّا في وقتنا الحالي فقد أصبحت وسائل الاتصالات أكثر نفعاً وبِسرعة
          أكبر حيث تمَّ تَقصير وقت الاتصال من عِدَّة أيَّام إلى مجرد دقائق معدودة، وقد أصبح الاعتماد في هذه الأيام على
          كُل مِن البريد الإلكتروني أو الرسائل النصية أو الدردشة المرئية أو الفاكس أو الرسائل الفورية، وغيرها من
          التقنيات التي تسمح للأشخاص بالاتصال ببعضهم البعض دون الحاجة إلى العثور على صندوق بريد. وسائل النقل
          (بالإنجليزية: Transportation): لقد كانت وسائل النقل سابِقاُ غير مُريحة، حيث كانت أريح وسائل النقل هي العربات
          التي تجرها الخيول والسُفن، أمَّا الآن فإن جميع وسائل النقل تتمتَّع بالسرعة والأمان والراحة كاستخدام السيارات
          الحديثة أو الطائرات وغيرها. وسائل الترفيه (بالإنجليزية: Entertainment): لقد كانت المسرحيات .
        </p>
      </>
    ),
  },
];
