import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  line-height: 0;
  position: relative;
  top: -25px;
  left: 15%;
  img {
    width: 126%;
    height: 100%;
    max-height: 500px;
    object-fit: cover;
    object-position: left;
  }
`;

const Medium = css`
  top: -40px;
  img {
    max-height: 440px;
  }
`;

const Large = css`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 45%;
  height: 100%;
  display: block;
  img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 0;
  }
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeroImage = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
