import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "../../../constants/mediaquerys";

const Small = css`
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0 32px 0;
  background: #f6f6f6;
`;

const Medium = css``;

const Large = css`
  padding: 0 120px;
`;

const XLarge = css`
  padding: 0 120px 0 190px;
`;

const XXLarge = css``;

export const SCompDownloadAppContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
