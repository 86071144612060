import { useEffect, useState } from "react";
import "../privacy-policy/privacy.css";
import axios from "axios";
import API_BASE_URL from "../../EnvConfig";
import Loader from "../../components/Loader";

const TermsConditions = () => {
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_BASE_URL}terms`)
      .then((res) => {
        setData(res.data.success.terms);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) return <Loader />;
  return (
    <main className="about-page">
      <h1>الشروط والأحكام</h1>
      <div className="main-content">
        {data?.map((d) => (
          <p key={d.id} dangerouslySetInnerHTML={{ __html: d.value }}></p>
        ))}
      </div>
    </main>
  );
};

export default TermsConditions;
